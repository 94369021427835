@import url(https://fonts.googleapis.com/css?family=Raleway:500,700,900|Bebas+Neue|Roboto+Slab:400,700|Roboto+Condensed:400,700&display=swap);
html, body{
  overflow-x: hidden;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.x0, .x1
{
  width: 100%!important;
}
h2
{
  font-size: 30px;
  font-family: "Raleway", sans-serif;
  -ms-font-feature-settings: "lnum" 1;
  -o-font-feature-settings: "lnum" 1;
  -webkit-font-feature-settings: "lnum" 1;
  font-feature-settings: "lnum" 1;
}
.ant-statistic
{
  display: -webkit-flex;
  display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    height: 100%;
}
.ant-card-body
{
  height: 100%;
}
.ant-layout-sider-children
{
  position: fixed;
}
.ant-layout-sider-children .ant-menu.ant-menu-inline-collapsed
{
  width: 50px!important;
}
@media only screen and (max-width: 720px) {
  h2 {
    font-size: 24px;
  }
}
.steps-section .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title
{
  color: white!important;
}
.steps-section  .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description
{
  color: rgba(255, 255, 255, 0.8)!important;
}
.steps-section .ant-steps-item-wait .ant-steps-item-icon
{
  border-color: white!important;
  background: transparent!important;
}
.steps-section .ant-steps-icon
{
  color: white!important;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}


.marquee {
    height: 100px;
    overflow: hidden;
    box-sizing: border-box;
    position: relative;
    margin: 16px auto;
  }
  
  .marquee .marquee--inner {
    display: block;
    width: 200%;
    position: absolute;
    -webkit-animation: marquee 20s linear infinite;
            animation: marquee 20s linear infinite;
  }
  
/*  .marquee--inner:hover {
    animation-play-state: paused;
  }
*/  
  /*.half {
    float: left;
    width: 10%;
  }*/
  
  .marquee span {
    float: left;
    width: 50%;
  }
  .orb {
    border-radius: 50px;
    display: inline-block;
    transition: all .2s ease-out;
  }
  @-webkit-keyframes marquee {
    0% { left: 0; }
    100% { left: -100%; }
  }
  @keyframes marquee {
    0% { left: 0; }
    100% { left: -100%; }
  }

  .marquee--inner.reverse {
    animation-direction: reverse;
  }
