@import url('https://fonts.googleapis.com/css?family=Raleway:500,700,900|Bebas+Neue|Roboto+Slab:400,700|Roboto+Condensed:400,700&display=swap');

html, body{
  overflow-x: hidden;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.x0, .x1
{
  width: 100%!important;
}
h2
{
  font-size: 30px;
  font-family: "Raleway", sans-serif;
  -moz-font-feature-settings: "lnum" 1;
  -moz-font-feature-settings: "lnum=1";
  -ms-font-feature-settings: "lnum" 1;
  -o-font-feature-settings: "lnum" 1;
  -webkit-font-feature-settings: "lnum" 1;
  font-feature-settings: "lnum" 1;
}
.ant-statistic
{
  display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}
.ant-card-body
{
  height: 100%;
}
.ant-layout-sider-children
{
  position: fixed;
}
.ant-layout-sider-children .ant-menu.ant-menu-inline-collapsed
{
  width: 50px!important;
}
@media only screen and (max-width: 720px) {
  h2 {
    font-size: 24px;
  }
}
.steps-section .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title
{
  color: white!important;
}
.steps-section  .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description
{
  color: rgba(255, 255, 255, 0.8)!important;
}
.steps-section .ant-steps-item-wait .ant-steps-item-icon
{
  border-color: white!important;
  background: transparent!important;
}
.steps-section .ant-steps-icon
{
  color: white!important;
}