
.marquee {
    height: 100px;
    overflow: hidden;
    box-sizing: border-box;
    position: relative;
    margin: 16px auto;
  }
  
  .marquee .marquee--inner {
    display: block;
    width: 200%;
    position: absolute;
    animation: marquee 20s linear infinite;
  }
  
/*  .marquee--inner:hover {
    animation-play-state: paused;
  }
*/  
  /*.half {
    float: left;
    width: 10%;
  }*/
  
  .marquee span {
    float: left;
    width: 50%;
  }
  .orb {
    border-radius: 50px;
    display: inline-block;
    transition: all .2s ease-out;
  }
  @keyframes marquee {
    0% { left: 0; }
    100% { left: -100%; }
  }

  .marquee--inner.reverse {
    animation-direction: reverse;
  }